.p-datatable-wrapper {
    font-size: inherit !important;
}
.p-component {
    font-size: inherit !important;
}
.p-paginator-page {
    font-size: inherit !important;
}
.p-inputtext {
    font-size: inherit !important;
}
.p-dropdown-item {
    font-size: inherit !important;
}
.p-dropdown-label {
    line-height: 22px;
}
.p-datatable-header {
    padding: 0 !important;
}
.p-datatable.p-datatable-gridlines .p-datatable-header {
    border-width: 0px 1px 0 1px !important;
}
.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
    padding: 0.2rem 0.75rem;
}
.p-steps .p-steps-item .p-menuitem-link {
    background: transparent !important;
}